import { useFetchSingleton } from '@/utils/api-manager/useFetch'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
interface ISelectedRows {
  id: string
  isApproved: boolean
}
export interface IOrganizationStatus {
  inActive: number
  pendingApprovals: number
  total: number
  totalAmountDisbursed: number
  remainingCreditLine: number
  totalInvoiceGenerated: number
}
export interface IStatements {
  isLoading: boolean
  statements: string[]
  page: number
  limit: number
  totalPage: number
  selectedRows: ISelectedRows[]
  totalStatements: number
  isAllRowsSelected: boolean
  organizationStatus: IOrganizationStatus
}
export const initialState: IStatements = {
  isLoading: false,
  statements: [],
  page: 1,
  limit: 10,
  totalPage: 0,
  selectedRows: [],
  totalStatements: 0,
  isAllRowsSelected: false,
  organizationStatus: {
    inActive: 0,
    pendingApprovals: 0,
    total: 0,
    totalAmountDisbursed: 0,
    remainingCreditLine: 0,
    totalInvoiceGenerated: 0,
  },
}

export const fetchVendorOrganizationStatus = createAsyncThunk('', async () => {
  const fetchApiInstance = useFetchSingleton.getInstance()
  const response = await fetchApiInstance.fetcher('GetVendorOrganizationStatus')

  return response?.data?.data as IOrganizationStatus
})

export const statementReducer = createSlice({
  name: 'statements',
  initialState,
  reducers: {
    setIsLoading: (state: IStatements, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setStatements: (state: IStatements, action: PayloadAction<any[]>) => {
      state.statements = action.payload
    },
    setPage: (state: IStatements, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setLimit: (state: IStatements, action: PayloadAction<number>) => {
      state.limit = action.payload
    },
    setTotalPage: (state: IStatements, action: PayloadAction<number>) => {
      state.totalPage = action.payload
    },
    setSelectedRows: (state: IStatements, action: PayloadAction<ISelectedRows[]>) => {
      state.selectedRows = action.payload
    },
    setIsAllRowsSelected: (state: IStatements, action: PayloadAction<boolean>) => {
      state.isAllRowsSelected = action.payload
    },
    setTotalStatements: (state: IStatements, action: PayloadAction<number>) => {
      state.totalStatements = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchVendorOrganizationStatus.fulfilled,
      (state, action: PayloadAction<IOrganizationStatus>) => {
        state.organizationStatus = action.payload
      },
    )
  },
})

export const {
  setIsLoading,
  setPage,
  setStatements,
  setIsAllRowsSelected,
  setTotalStatements,
  setLimit,
  setTotalPage,
  setSelectedRows,
} = statementReducer.actions
export default statementReducer.reducer
