import { ILoanStatuesResults } from '@/extension-portal/types/loanStatus/loan-status-entities.type'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ISelectedRows {
  id: string
  isApproved: boolean
}

export interface ILoanStatuses {
  isLoading: boolean
  loanStatuses: ILoanStatuesResults[]
  page: number
  limit: number
  totalPage: number
  selectedRows: ISelectedRows[]
  totalUsers: number
  isAllRowsSelected: boolean
}

export const initialState: ILoanStatuses = {
  isLoading: false,
  loanStatuses: [],
  page: 1,
  limit: 10,
  totalPage: 0,
  selectedRows: [],
  totalUsers: 0,
  isAllRowsSelected: false,
}

export const loanStatusReducer = createSlice({
  name: 'loanStatus',
  initialState,
  reducers: {
    setIsLoading: (state: ILoanStatuses, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setLoanStatuses: (state: ILoanStatuses, action: PayloadAction<Record<string, any>[]>) => {
      state.loanStatuses = action.payload as ILoanStatuesResults[]
    },
    setPage: (state: ILoanStatuses, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setLimit: (state: ILoanStatuses, action: PayloadAction<number>) => {
      state.limit = action.payload
    },
    setTotalPage: (state: ILoanStatuses, action: PayloadAction<number>) => {
      state.totalPage = action.payload
    },
    setSelectedRows: (state: ILoanStatuses, action: PayloadAction<ISelectedRows[]>) => {
      state.selectedRows = action.payload
    },
    setIsAllRowsSelected: (state: ILoanStatuses, action: PayloadAction<boolean>) => {
      state.isAllRowsSelected = action.payload
    },
    setTotalUsers: (state: ILoanStatuses, action: PayloadAction<number>) => {
      state.totalUsers = action.payload
    },
  },
})

export const {
  setIsLoading,
  setPage,
  setLoanStatuses,
  setIsAllRowsSelected,
  setTotalUsers,
  setLimit,
  setTotalPage,
  setSelectedRows,
} = loanStatusReducer.actions
export default loanStatusReducer.reducer
