import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useFetchSingleton } from '@/utils/api-manager/useFetch'

interface ISelectedRows {
  id: string
  isApproved: boolean
}

export interface IOrganizationStatus {
  inActive: number
  pendingApprovals: number
  total: number
  totalAmountDisbursed: number
  remainingCreditLine: number
  totalInvoiceGenerated: number
}

export interface IVendors {
  isLoading: boolean
  vendor: string[]
  page: number
  limit: number
  totalPage: number
  totalUsers: number
  selectedRows: ISelectedRows[]
  isAllRowsSelected: boolean
  organizationStatus: IOrganizationStatus
}

export const initialState: IVendors = {
  isLoading: false,
  vendor: [],
  page: 1,
  limit: 10,
  totalPage: 0,
  selectedRows: [],
  totalUsers: 0,
  isAllRowsSelected: false,
  organizationStatus: {
    inActive: 0,
    pendingApprovals: 0,
    total: 0,
    totalAmountDisbursed: 0,
    remainingCreditLine: 0,
    totalInvoiceGenerated: 0,
  },
}

export const fetchVendorOrganizationStatus = createAsyncThunk('', async () => {
  const instance = useFetchSingleton.getInstance()
  const response = await instance.fetcher('GetVendorOrganizationStatus')
  return response?.data?.data as IOrganizationStatus
})

export const vendorReducer = createSlice({
  name: 'vendor',
  initialState,
  reducers: {
    setIsLoading: (state: IVendors, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setVendor: (state: IVendors, action: PayloadAction<any[]>) => {
      state.vendor = action.payload
    },
    setPage: (state: IVendors, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setLimit: (state: IVendors, action: PayloadAction<number>) => {
      state.limit = action.payload
    },
    setTotalPage: (state: IVendors, action: PayloadAction<number>) => {
      state.totalPage = action.payload
    },
    setSelectedRows: (state: IVendors, action: PayloadAction<ISelectedRows[]>) => {
      state.selectedRows = action.payload
    },
    setIsAllRowsSelected: (state: IVendors, action: PayloadAction<boolean>) => {
      state.isAllRowsSelected = action.payload
    },
    setTotalUsers: (state: IVendors, action: PayloadAction<number>) => {
      state.totalUsers = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchVendorOrganizationStatus.fulfilled,
      (state, action: PayloadAction<IOrganizationStatus>) => {
        state.organizationStatus = action.payload
      },
    )
  },
})

export const {
  setIsLoading,
  setPage,
  setLimit,
  setTotalPage,
  setSelectedRows,
  setVendor,
  setIsAllRowsSelected,
  setTotalUsers,
} = vendorReducer.actions
export default vendorReducer.reducer
