import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ISelectedRows {
  id: string
  isApproved: boolean
}

export interface IBillPayments {
  isLoading: boolean
  payments: string[]
  page: number
  limit: number
  totalPage: number
  selectedRows: ISelectedRows[]
  totalUsers: number
  isAllRowsSelected: boolean
}
export const initialState: IBillPayments = {
  isLoading: false,
  payments: [],
  page: 1,
  limit: 10,
  totalPage: 0,
  selectedRows: [],
  totalUsers: 0,
  isAllRowsSelected: false,
}

export const billPaymentReducer = createSlice({
  name: 'billPayment',
  initialState,
  reducers: {
    setIsLoading: (state: IBillPayments, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setBillPayment: (state: IBillPayments, action: PayloadAction<any[]>) => {
      state.payments = action.payload
    },
    setPage: (state: IBillPayments, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setLimit: (state: IBillPayments, action: PayloadAction<number>) => {
      state.limit = action.payload
    },
    setTotalPage: (state: IBillPayments, action: PayloadAction<number>) => {
      state.totalPage = action.payload
    },
    setSelectedRows: (state: IBillPayments, action: PayloadAction<ISelectedRows[]>) => {
      state.selectedRows = action.payload
    },
    setIsAllRowsSelected: (state: IBillPayments, action: PayloadAction<boolean>) => {
      state.isAllRowsSelected = action.payload
    },
    setTotalUsers: (state: IBillPayments, action: PayloadAction<number>) => {
      state.totalUsers = action.payload
    },
  },
})

export const {
  setIsLoading,
  setPage,
  setBillPayment,
  setIsAllRowsSelected,
  setTotalUsers,
  setLimit,
  setTotalPage,
  setSelectedRows,
} = billPaymentReducer.actions
export default billPaymentReducer.reducer
