import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ISelectedRows {
  id: string
  isApproved: boolean
}

interface InvoiceOverview {
  totalAmount: number
  totalUsedAmount: number
  remainingAmount: number
}

export interface InvoiceRefactoringReducer {
  isLoading: boolean
  invoices: string[]
  page: number
  limit: number
  totalPage: number
  selectedRows: ISelectedRows[]
  totalUsers: number
  isAllRowsSelected: boolean
  invoiceOverview: InvoiceOverview
}

export const initialState: InvoiceRefactoringReducer = {
  isLoading: false,
  invoices: [],
  page: 1,
  limit: 10,
  totalPage: 0,
  selectedRows: [],
  totalUsers: 0,
  isAllRowsSelected: false,
  invoiceOverview: {
    totalAmount: 0,
    totalUsedAmount: 0,
    remainingAmount: 0,
  },
}

export const invoiceRefactoringReducer = createSlice({
  name: 'invoiceRefactoring',
  initialState,
  reducers: {
    setIsLoading: (state: InvoiceRefactoringReducer, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setInvoices: (state: InvoiceRefactoringReducer, action: PayloadAction<any[]>) => {
      state.invoices = action.payload
    },
    setPage: (state: InvoiceRefactoringReducer, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setLimit: (state: InvoiceRefactoringReducer, action: PayloadAction<number>) => {
      state.limit = action.payload
    },
    setTotalPage: (state: InvoiceRefactoringReducer, action: PayloadAction<number>) => {
      state.totalPage = action.payload
    },
    setSelectedRows: (state: InvoiceRefactoringReducer, action: PayloadAction<ISelectedRows[]>) => {
      state.selectedRows = action.payload
    },
    setIsAllRowsSelected: (state: InvoiceRefactoringReducer, action: PayloadAction<boolean>) => {
      state.isAllRowsSelected = action.payload
    },
    setTotalUsers: (state: InvoiceRefactoringReducer, action: PayloadAction<number>) => {
      state.totalUsers = action.payload
    },
    setInvoiceOverview: (
      state: InvoiceRefactoringReducer,
      action: PayloadAction<InvoiceOverview>,
    ) => {
      state.invoiceOverview = action.payload
    },
  },
})

export const {
  setIsLoading,
  setPage,
  setInvoices,
  setIsAllRowsSelected,
  setTotalUsers,
  setLimit,
  setTotalPage,
  setSelectedRows,
  setInvoiceOverview,
} = invoiceRefactoringReducer.actions
export default invoiceRefactoringReducer.reducer
