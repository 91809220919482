import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ISelectedRows {
  id: string
  isApproved: boolean
}

export interface IBillTransitions {
  isLoading: boolean
  billTransitions: string[]
  page: number
  limit: number
  totalPage: number
  selectedRows: ISelectedRows[]
  totalUsers: number
  isAllRowsSelected: boolean
}
export const initialState: IBillTransitions = {
  isLoading: false,
  billTransitions: [],
  page: 1,
  limit: 10,
  totalPage: 0,
  selectedRows: [],
  totalUsers: 0,
  isAllRowsSelected: false,
}

export const billPaymentTransitionsReducer = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setIsLoading: (state: IBillTransitions, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setBillTransitions: (state: IBillTransitions, action: PayloadAction<any[]>) => {
      state.billTransitions = action.payload
    },
    setPage: (state: IBillTransitions, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setLimit: (state: IBillTransitions, action: PayloadAction<number>) => {
      state.limit = action.payload
    },
    setTotalPage: (state: IBillTransitions, action: PayloadAction<number>) => {
      state.totalPage = action.payload
    },
    setSelectedRows: (state: IBillTransitions, action: PayloadAction<ISelectedRows[]>) => {
      state.selectedRows = action.payload
    },
    setIsAllRowsSelected: (state: IBillTransitions, action: PayloadAction<boolean>) => {
      state.isAllRowsSelected = action.payload
    },
    setTotalUsers: (state: IBillTransitions, action: PayloadAction<number>) => {
      state.totalUsers = action.payload
    },
  },
})

export const {
  setIsLoading,
  setPage,
  setBillTransitions,
  setIsAllRowsSelected,
  setTotalUsers,
  setLimit,
  setTotalPage,
  setSelectedRows,
} = billPaymentTransitionsReducer.actions
export default billPaymentTransitionsReducer.reducer
