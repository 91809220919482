import {
  IRepaymentsResponse,
  ISelectedRows,
  IOrganizationStatus,
} from '@/extension-portal/types/repayments/repayment.types'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useFetchSingleton } from '@/utils/api-manager/useFetch'

export const initialState: IRepaymentsResponse = {
  isLoading: false,
  repayments: [],
  page: 1,
  limit: 10,
  totalPage: 0,
  selectedRows: [],
  totalUsers: 0,
  isAllRowsSelected: false,
  organizationStatus: {
    tkRepayments: 0,
  },
}

export const fetchRepaymentOrganizationStatus = createAsyncThunk('', async () => {
  const instance = useFetchSingleton.getInstance()
  const response = await instance.fetcher('GetVendorOrganizationStatus')
  return response?.data?.data as IOrganizationStatus
})

export const repaymentReducer = createSlice({
  name: 'repayments',
  initialState,
  reducers: {
    setIsLoading: (state: IRepaymentsResponse, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setRepayments: (state: IRepaymentsResponse, action: PayloadAction<any[]>) => {
      state.repayments = action.payload
    },
    setPage: (state: IRepaymentsResponse, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setLimit: (state: IRepaymentsResponse, action: PayloadAction<number>) => {
      state.limit = action.payload
    },
    setTotalPage: (state: IRepaymentsResponse, action: PayloadAction<number>) => {
      state.totalPage = action.payload
    },
    setSelectedRows: (state: IRepaymentsResponse, action: PayloadAction<ISelectedRows[]>) => {
      state.selectedRows = action.payload
    },
    setIsAllRowsSelected: (state: IRepaymentsResponse, action: PayloadAction<boolean>) => {
      state.isAllRowsSelected = action.payload
    },
    setTotalUsers: (state: IRepaymentsResponse, action: PayloadAction<number>) => {
      state.totalUsers = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchRepaymentOrganizationStatus.fulfilled,
      (state, action: PayloadAction<IOrganizationStatus>) => {
        state.organizationStatus = action.payload
      },
    )
  },
})

export const {
  setIsLoading,
  setRepayments,
  setPage,
  setLimit,
  setTotalPage,
  setSelectedRows,
  setIsAllRowsSelected,
  setTotalUsers,
} = repaymentReducer.actions
export default repaymentReducer.reducer
