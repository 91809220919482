import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ISelectedRows {
  id: string
  isApproved: boolean
}

export interface IPayments {
  isLoading: boolean
  payments: string[]
  page: number
  limit: number
  totalPage: number
  selectedRows: ISelectedRows[]
  totalUsers: number
  isAllRowsSelected: boolean
}

export const initialState: IPayments = {
  isLoading: false,
  payments: [],
  page: 1,
  limit: 10,
  totalPage: 0,
  selectedRows: [],
  totalUsers: 0,
  isAllRowsSelected: false,
}

export const paymentReducer = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setIsLoading: (state: IPayments, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setPayments: (state: IPayments, action: PayloadAction<any[]>) => {
      state.payments = action.payload
    },
    setPage: (state: IPayments, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setLimit: (state: IPayments, action: PayloadAction<number>) => {
      state.limit = action.payload
    },
    setTotalPage: (state: IPayments, action: PayloadAction<number>) => {
      state.totalPage = action.payload
    },
    setSelectedRows: (state: IPayments, action: PayloadAction<ISelectedRows[]>) => {
      state.selectedRows = action.payload
    },
    setIsAllRowsSelected: (state: IPayments, action: PayloadAction<boolean>) => {
      state.isAllRowsSelected = action.payload
    },
    setTotalUsers: (state: IPayments, action: PayloadAction<number>) => {
      state.totalUsers = action.payload
    },
  },
})

export const {
  setIsLoading,
  setPage,
  setPayments,
  setIsAllRowsSelected,
  setTotalUsers,
  setLimit,
  setTotalPage,
  setSelectedRows,
} = paymentReducer.actions
export default paymentReducer.reducer
